import React from "react"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const Index = ({ pictures: images, title, subtitle, richContent, styling }) => {
  const mains = images?.map(full => full.mainSrc)

  const [photoIndex, setPhotoIndex] = React.useState(0)

  const [lightBoxOpen, setLightBoxOpen] = React.useState(false)
  const showImageInLightBox = index => {
    setPhotoIndex(index)
    setLightBoxOpen(true)
  }
  return (
    <section className={styling}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            {title && <h4 className="uppercase mb16">{title}</h4>}
            {(richContent || subtitle) && (
              <div className="lead mb64">
                {richContent
                  ? documentToReactComponents(richContent)
                  : subtitle}
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            {images && (
              <div
                className="lightbox-grid square-thumbs"
                data-gallery-title="Gallery"
              >
                <ul>
                  {images.map((pic, i) => (
                    <li key={pic.id}>
                      <div onClick={() => showImageInLightBox(i)} role="none">
                        <div className="background-image-holder">
                          <img
                            alt={pic.title}
                            className="background-image"
                            src={pic.thumbnail}
                          />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {lightBoxOpen && (
              <Lightbox
                mainSrc={mains[photoIndex]}
                nextSrc={mains[(photoIndex + 1) % mains.length]}
                prevSrc={mains[(photoIndex + mains.length - 1) % mains.length]}
                onCloseRequest={() => setLightBoxOpen(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex((photoIndex + mains.length - 1) % mains.length)
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % mains.length)
                }
                imageTitle={images[photoIndex].subtitle}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Index
