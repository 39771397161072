import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Hero from "../components/Hero"
import GalleryAndLightBox from "../components/GalleryAndLightBox"

export const query = graphql`
  query ($slug: String!) {
    contentfulGallery(slug: { eq: $slug }) {
      id
      title
      subtitle
      mainContent {
        raw
      }
      textContent {
        textContent
      }
      category
      thumbnail {
        fluid(maxWidth: 2560, maxHeight: 1440, quality: 100, toFormat: AUTO) {
          src
        }
      }
      pictures {
        id
        title
        fluid(quality: 100, toFormat: AUTO) {
          src
        }
        fixed(width: 500, height: 500, quality: 30, toFormat: WEBP) {
          src
        }
        file {
          url
        }
      }
    }
  }
`

const Gallery = props => {
  const { contentfulGallery } = props.data

  const bannerImage = {
    title: contentfulGallery?.title,
    file: {
      url: contentfulGallery?.thumbnail?.fluid?.src,
    },
  }
  const pictures = contentfulGallery?.pictures?.map(pic => ({
    id: pic.id,
    thumbnail: pic.fixed.src,
    mainSrc: pic.fluid.src,
    title: pic.title,
    subtitle: contentfulGallery.subtitle,
  }))
  // const richContent = JSON.parse(contentfulGallery?.mainContent?.raw)

  const safelyParse = data => {
    try {
      if (data) {
        return JSON.parse(data)
      } else {
        return null
      }
    } catch (e) {
      return null
    }
  }

  const richContent = safelyParse(contentfulGallery?.mainContent?.raw)

  return (
    <Layout>
      <div className="main-container">
        <Hero heroImage={bannerImage} title={contentfulGallery.title} />
      </div>
      <GalleryAndLightBox
        styling="pad-30"
        pictures={pictures}
        title={contentfulGallery.title}
        subtitle={contentfulGallery?.textContent?.textContent}
        richContent={richContent}
      />
    </Layout>
  )
}

export default Gallery
